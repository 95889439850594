<template>
    <div class="Inviteposter">
        <vue-canvas-poster :widthPixels="1080" :painting="painting" @success="canvasSuccess" @fail="canvasFail">
        </vue-canvas-poster>
        <van-image width="100vw" height="100vh" fit="contain" :src="posterImg" />
    </div>
</template>

<script>
    import QRCode from "qrcodejs2"; //生成二维码
    import html2canvas from "html2canvas"; //html转图片
    import {
        getBase64Image
    } from "@/common/data";
    
    export default {
        data() {
            return {
                shareLink: "",
                inviteBg: "",
                userInfo: {},
                posterImg: "",
                site_code: ""
            };
        },
        created() {
            this.site_code = this.$LStorage.getItem("curSiteInfo").site_code;
            this.userInfo =this.$LStorage.getItem("userInfo")
            
            this.inviteBg = 'http://yizhe-haibao.oss-cn-beijing.aliyuncs.com/' + this.site_code + '.jpg';
            this.shareLink = window.location.origin + "/invite/users?share_code=" + this.userInfo.user_code + "&site_code=" + this.site_code;
            this.$toast.loading("生成中");
        },
        computed: {
            painting() {
                let data = {
                    width: "1080px",
                    height: "2090px",
                    // background: this.userInfo.haibao + '?' + Math.random(),
                    background: this.inviteBg,
                    views: [{
                            type: "text",
                            text: this.userInfo.nickname,
                            css: {
                                bottom: "85px",
                                right: "80px",
                                width: "240px",
                                color: "#fff",
                                maxLines: 1,
                                fontSize: "34px",
                                textAlign: "center"
                            }
                        },
                        {
                            type: "qrcode",
                            content: this.shareLink,
                            css: {
                                bottom: "150px",
                                right: "80px",
                                color: "#000",
                                background: "#fff",
                                width: "240px",
                                height: "240px",
                                borderWidth: "5px",
                                borderColor: "#fff"
                            }
                        }
                    ]
                };
                return data;
            }
        },
        methods: {
            //成功生成海报
            canvasSuccess(src) {
                this.posterImg = src; //生成是base64格式的图片
                this.$toast.clear();
            },
            //无法生成海报
            canvasFail(err) {
                this.$toast.clear();
                this.$toast(err);
            }
        }
    };
</script>

<style lang="scss">
    .Inviteposter {
        width: 100%;
        height: 100vh;
    }
</style>
